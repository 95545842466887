<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getListStatementETI"
)
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'StatementETI',
  data () {
    return {
      headers: [
        { value: 'date_create',
          text: this.$t('createDate')
        },
        { value: 'number',
          text: this.$t('number')
        },
        { value: 'sailorFullName',
          text: this.$t('fullName'),
          sortable: false

        },
        { value: 'course',
          text: this.$t('course')
        },
        { value: 'institution',
          text: this.$t('nameInstitution')
        },
        { value: 'date_meeting',
          text: this.$t('dateStartEdu')
        },
        { value: 'date_end_meeting',
          text: this.$t('dateEndEdu')
        },
        { value: 'is_payed',
          text: this.$t('payment')
        },
        { value: 'status_document',
          text: this.$t('status')
        },
        { value: 'event',
          text: this.$t('actions'),
          class: 'mw-0',
          sortable: false
        }
      ],
      actions: [
        {
          id: 1,
          to: ({ id, sailor }) => ({
            name: 'certification-certificates',
            params: { id: sailor.id, documentID: id },
            query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.statement.statementETI,
      isLoading: state => state.statement.isLoading
    })
  },
  methods: {
    ...mapActions(['getListStatementETI'])
  }
}
</script>
